
import WeatherIcon from "@evercam/shared/components/weather/WeatherIcon"
import {
  AnalyticsEvent,
  UnitSystem,
  WeatherProvider,
} from "@evercam/shared/types"
import Vue from "vue"
import { mapStores } from "pinia"
import { useWeatherStore } from "@evercam/dashboard/stores/weather"

export default Vue.extend({
  name: "WeatherContentHeader",
  components: { WeatherIcon },
  props: {
    hoveredWeather: {
      type: Object,
      default: () => ({
        wind: null,
        precipitation: null,
        temperature: null,
      }),
    },
    weather: {
      type: [Object, Array, null],
      default: null,
    },
    unitSystem: {
      type: String,
      default: () => "",
    },
    targetTimestamp: {
      type: [String, Date],
      default: "",
    },
    selectedCamera: {
      type: Object,
      default: () => ({}),
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      unitsSystem: UnitSystem,
      menu: false,
      weatherProviders: [
        { text: "Open Weather Map", value: WeatherProvider.OpenWeatherMap },
        { text: "Own Device", value: WeatherProvider.AerisWeather },
      ],
    }
  },
  computed: {
    ...mapStores(useWeatherStore),
    maxDate() {
      return this.$moment().toISOString()
    },
    timezone() {
      return this.selectedCamera?.timezone || "Europe/Dublin"
    },
    notCurrentDay() {
      return !!this.$moment().diff(this.$moment(this.targetTimestamp), "days")
    },
    currentWeather() {
      if (this.targetTimestamp && this.weather?.length) {
        const weather = this.weather.find((entry) => entry.isRequestedWeather)

        return weather || this.weather[0]
      } else {
        return this.weather?.length ? this.weather[0] : {}
      }
    },
    dateTime() {
      return this.$moment
        .tz(this.currentWeather?.hour, this.timezone)
        .format("MMM DD, YYYY")
    },
    disableNextDateIcon() {
      if (!this.weather) {
        return true
      }
      const targetTimestamp = this.$moment(this.targetTimestamp).format(
        "MMM DD, YYYY"
      )
      const weather = this.weather.find((entry) => entry.isRequestedWeather)
      const maxDate = this.$moment(this.maxDate).format("MMM DD, YYYY")

      return (
        this.dateTime === maxDate || (targetTimestamp === maxDate && !weather)
      )
    },
    calendarDateTime() {
      return this.$moment
        .tz(this.currentWeather?.hour, this.timezone)
        .format("YYYY-MM-DD")
    },
    temperature() {
      const hoverTemp = this.hoveredWeather.temperature
      if (hoverTemp != null && hoverTemp != undefined) {
        return Math.floor(this.hoveredWeather.temperature)
      }
      const temperature =
        this.currentWeather.temp || this.hoveredWeather.temperature

      if (this.targetTimestamp && this.notCurrentDay && temperature >= 273) {
        return Number.parseInt(
          this.unitSystem === UnitSystem.Imperial
            ? this.$units.kelvinToFahrenheit(temperature)
            : this.$units.kelvinToCelsius(temperature)
        )
      } else {
        return Number.parseInt(
          this.unitSystem === UnitSystem.Imperial
            ? this.$units.celsiusToFahrenheit(temperature)
            : temperature
        )
      }
    },
    windSpeed() {
      const hoverWind = this.hoveredWeather.wind
      if (hoverWind != null && hoverWind != undefined) {
        return Math.floor(this.hoveredWeather.wind)
      }
      const speed =
        this.currentWeather.windSpeed || this.currentWeather.wind?.speed

      return Number.parseFloat(
        this.unitSystem === UnitSystem.Imperial
          ? this.$units.kmhToMilesPerHour(speed)
          : speed
      ).toFixed(1)
    },
    humidity() {
      const hoverHumidity = this.hoveredWeather.humidity
      if (hoverHumidity != null && hoverHumidity != undefined) {
        return this.hoveredWeather.humidity
      }

      return this.currentWeather.humidity
    },
    weatherDescription() {
      if (this.currentWeather?.weather?.length) {
        return (
          this.currentWeather.weather[0]?.main ||
          this.currentWeather.weather[0]?.description
        )
      }

      return ""
    },
    precipitation() {
      const hoveredRain = this.hoveredWeather.precipitation
      if (hoveredRain != null && hoveredRain != undefined) {
        return `${hoveredRain} ${this.$t(
          "content.units." + this.unitSystem + ".rain"
        )}`
      }

      let rainVolume =
        this.currentWeather.weather && this.currentWeather.weather[0]?.rain
          ? this.currentWeather.weather[0].rain["1h"]
          : 0
      if (this.unitSystem === UnitSystem.Imperial) {
        rainVolume = this.$units.mmToInches(rainVolume)
      }

      return `${Number.parseInt(`${rainVolume * 100}`)} ${this.$t(
        "content.units." + this.unitSystem + ".rain"
      )}`
    },
    isMobile() {
      return this.$device.isMobile
    },
    selectedProjectSupportsWeatherBYOD() {
      return this.$permissions.project.has.weatherBYOD()
    },
  },
  methods: {
    onChangeDate(date) {
      this.$emit("navigate-weather-by-date", date)
    },
    selectTemperatureScale(unitSystem) {
      this.$emit("update-unit-system", unitSystem)
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.weatherSelectTempScale,
        params: {
          scale: unitSystem === UnitSystem.Imperial ? "Farenheit" : "Celcius",
        },
      })
    },
    selectWeatherProvider(provider) {
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.weatherSelectProvider,
        params: { provider },
      })
    },
  },
})
