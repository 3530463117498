
import CameraStatusIcon from "@evercam/shared/components/CameraStatusIcon"
import BatteryLevelIcon from "@evercam/shared/components/BatteryLevelIcon"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useBatteryReadingsStore } from "@/stores/batteryReadings"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useMediaHubStore } from "@/stores/mediaHub"

import Breadcrumbs from "@/components/breadcrumbs/BreadcrumbContainer"
import BreadcrumbsItem from "@/components/breadcrumbs/BreadcrumbItem"
import { camelizeKeys } from "humps"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  components: {
    CameraStatusIcon,
    BatteryLevelIcon,
    Breadcrumbs,
    BreadcrumbsItem,
  },
  data() {
    return {
      feature: null,
      selectedMedia: null,
      dialog: false,
    }
  },

  computed: {
    ...mapStores(
      useLayoutStore,
      useBatteryReadingsStore,
      useProjectStore,
      useCameraStore,
      useAccountStore,
      useMediaHubStore
    ),
    routeCameraExid() {
      return camelizeKeys(this.$route.params)?.cameraExid
    },
    routeProjectExid() {
      return camelizeKeys(this.$route.params)?.projectExid
    },
    breadcrumbs() {
      const breadcrumbs = []
      breadcrumbs.push({
        text: "Projects",
        disabled: false,
        href: "/v2/projects",
      })
      if (
        this.projectStore.selectedProject &&
        this.routeProjectExid === this.projectStore.selectedProjectExid
      ) {
        const otherProjects = this.projectStore.projects.filter(
          (project) => project.exid !== this.projectStore.selectedProjectExid
        )
        otherProjects.forEach((project) => {
          project.href = `/v2/projects/${project.exid}`
          project.text = project.name
        })
        breadcrumbs.push({
          text: this.projectStore.selectedProjectName,
          disabled: false,
          href: this.projectStore.projectRoute,
          otherItems: otherProjects,
        })
      }

      if (
        this.projectStore.selectedProject &&
        this.cameraStore.selectedCamera &&
        this.routeCameraExid === this.cameraStore.selectedCameraExid
      ) {
        let otherCameras = []
        if (this.accountStore.token) {
          otherCameras = this.projectStore.selectedProjectCameras.filter(
            (camera) => camera.id !== this.cameraStore.selectedCameraExid
          )
          otherCameras.forEach((camera) => {
            camera.href = `${this.projectStore.projectRoute}/${camera.id}`
            camera.text = camera.name
            camera.isCamera = true
          })
        } else {
          breadcrumbs.push({
            text: this.projectStore.selectedProjectName,
            disabled: true,
            href: "",
          })
        }

        breadcrumbs.push({
          text: this.cameraStore.selectedCamera.name,
          exid: this.cameraStore.selectedCameraExid,
          voltage: this.batteryReadingsStore.getBatteryVoltage(
            this.cameraStore.selectedCameraExid
          ),
          isCamera: true,
          status: this.cameraStore.selectedCamera?.status,
          disabled: true,
          href: this.accountStore.token
            ? `${this.cameraStore.cameraRoute}`
            : "",
          otherItems: otherCameras,
        })
      }

      if (!this.routeProjectExid) {
        return breadcrumbs
      }

      if (this.feature) {
        breadcrumbs.push({
          text: this.feature.text,
          otherItems: this.feature.otherItems,
          disabled: true,
        })
      }

      if (this.selectedMedia) {
        breadcrumbs.push({
          text: this.selectedMedia.text,
          otherItems: this.selectedMedia.otherItems,
          disabled: true,
        })
      }

      return breadcrumbs
    },
    projectRoutes() {
      return [
        {
          path: `${this.projectBaseRoute}/${
            this.$permissions.user.can.access.gateReport() &&
            this.$permissions.project.has.gateReport()
              ? "gate-report"
              : "info/gate-report"
          }`,
          translationKey: "sections.gate_report",
          isVisible: true,
        },
        {
          path: `${this.projectBaseRoute}/${
            this.$permissions.project.has.droneView()
              ? "drone"
              : "info/drone-view"
          }`,
          translationKey: "sections.droneView",
          isVisible: true,
        },
        {
          path: `${this.projectBaseRoute}/${
            this.$permissions.project.has.BIMView() ? "bim" : "info/bim"
          }`,
          translationKey: "sections.BIMView",
          isVisible: true,
        },
        {
          path: `${this.projectBaseRoute}/${
            this.$permissions.project.has._360View() ? "360" : "info/360"
          }`,
          translationKey: "sections._360View",
          isVisible: true,
        },
        {
          path: `${this.projectBaseRoute}/video-wall`,
          translationKey: "sections.video_wall",
          isVisible: this.$permissions.user.can.access.videoWalls(),
        },
        {
          path: `${this.projectBaseRoute}/timeline`,
          translationKey: "pages.timeline",
          isVisible: true,
        },
        {
          path: `${this.projectBaseRoute}/media-hub`,
          translationKey: "pages.media_hub",
          isVisible: this.$permissions.user.can.access.mediaHub(),
        },
        {
          path: `${this.projectBaseRoute}/team`,
          translationKey: "content.project_members.title",
          isVisible: this.$permissions.user.can.access.massCameraSharing(),
        },
        {
          path: "/v2/automations/progress-photos",
          translationKey: "sections.automations",
          isVisible: true,
        },
        {
          path: `${this.projectBaseRoute}/map`,
          translationKey: "sections.map_view",
          isVisible: this.isSidebarAllowed(),
        },
        {
          path: `${this.projectBaseRoute}/site-planner`,
          translationKey: "sections.site_planner",
          isVisible: true,
        },
      ]
    },
    projectBaseRoute() {
      return `${this.projectStore.projectRoute}`
    },
    mediaExid() {
      return this.$route.params.mediaExid
    },
  },

  watch: {
    $route: {
      async handler(route) {
        if (!route?.fullPath) {
          return
        }
        this.feature = await this.getFeatureName(route.fullPath)

        const isMediaOverviewPage = route?.fullPath?.includes(
          `${this.projectBaseRoute}/media-hub/${this.mediaExid}`
        )
        this.selectedMedia = isMediaOverviewPage
          ? await this.handleMediaHub()
          : null
      },
      immediate: true,
    },
  },

  methods: {
    isProjectView() {
      return (
        !!this.routeProjectExid &&
        (this.projectStore.selectedProjectCameras.length > 0 ||
          !!this.cameraStore.selectedCamera)
      )
    },
    async handleMediaHub() {
      const response = await EvercamApi.mediaHub.cShow(
        this.routeProjectExid as string,
        this.mediaExid
      )

      return {
        text: response.title,
        disabled: true,
        otherItems: [],
      }
    },

    isSidebarAllowed() {
      return (
        this.accountStore.token &&
        this.isProjectView() &&
        this.projectStore.selectedProject &&
        !this.$permissions.user.is.restricted()
      )
    },
    getCameraFeatureName(currentPath) {
      if (!this.cameraStore.isCameraSharedWithUser) {
        return
      }
      const cameraBaseRoute = `${this.cameraStore.cameraRoute}`

      const cameraRoutes = [
        {
          path: `${cameraBaseRoute}/recordings/snapshots`,
          translationKey: "pages.recordings",
          isVisible: this.$permissions.user.can.access.recordings(),
        },
        {
          path: `${cameraBaseRoute}/timelapse`,
          translationKey: "pages.timelapse",
          isVisible: this.$permissions.user.can.access.timelapse(),
        },
        {
          path: `${cameraBaseRoute}/shares`,
          translationKey: "pages.sharing",
          isVisible: this.$permissions.user.can.access.sharing(),
        },
        {
          path: `${cameraBaseRoute}/compare`,
          translationKey: "pages.compare",
          isVisible: this.$permissions.user.can.access.compare(),
        },
        {
          path: `${cameraBaseRoute}/anpr`,
          translationKey: "pages.anpr",
          isVisible:
            this.$permissions.camera.has.anpr() &&
            this.$permissions.user.can.access.anpr(),
        },
        {
          path: `${cameraBaseRoute}/${
            this.$permissions.user.can.access.bim() &&
            this.$permissions.camera.has.bim()
              ? "bim-compare"
              : "info/bim-compare"
          }`,
          translationKey: "pages.bim_compare",
          isVisible: true,
        },
        // ! -- live view should be the last item
        {
          path: cameraBaseRoute,
          translationKey: "pages.live_view",
          isVisible: this.cameraStore.hasCameraLiveView,
        },
      ]
      const matchingPath = cameraRoutes.find(
        (route) => currentPath.includes(route.path) && route.isVisible
      )
      let otherItems = cameraRoutes.filter(
        (item) =>
          item.isVisible && item.translationKey !== matchingPath.translationKey
      )
      // make live view appear on top of the list
      const isLiveViewIncluded = otherItems.find(
        (item) => item.translationKey === "pages.live_view"
      )
      if (isLiveViewIncluded) {
        const liveViewItem = otherItems.splice(otherItems.length - 1, 1)
        otherItems.unshift(liveViewItem[0])
      }

      return {
        text: this.$t(
          matchingPath ? matchingPath.translationKey : "pages.live_view"
        ),
        otherItems: otherItems.map((route) => ({
          text: this.$t(route.translationKey),
          href: route.path,
          hasPermission: route.isVisible,
        })),
      }
    },

    getProjectFeatureName(currentPath) {
      if (!this.isSidebarAllowed()) {
        return
      }

      const matchingPath = this.projectRoutes.find(
        (route) => currentPath.includes(route.path) && route.isVisible
      )

      const otherItems = this.projectRoutes.filter(
        (item) =>
          item.isVisible && item.translationKey !== matchingPath?.translationKey
      )

      return {
        text: this.$t(
          matchingPath ? matchingPath.translationKey : "pages.live_view"
        ),
        otherItems: otherItems.map((route) => ({
          text: this.$t(route.translationKey),
          href: route.path,
        })),
      }
    },

    async getFeatureName(currentPath) {
      if (
        this.cameraStore.selectedCamera &&
        this.routeCameraExid === this.cameraStore.selectedCameraExid
      ) {
        return this.getCameraFeatureName(currentPath)
      }

      return this.getProjectFeatureName(currentPath)
    },
  },
}
