
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import WeatherContentHeader from "@evercam/dashboard/components/weather/WeatherContentHeader"
import WeatherChart from "@evercam/shared/components/weather/WeatherChart"
import { mapStores } from "pinia"
import { useLayoutStore } from "@evercam/dashboard/stores/layout"
import { useWeatherStore } from "@/stores/weather"
import { AnalyticsEvent, UnitSystem } from "@evercam/shared/types"

export default {
  name: "WeatherDialog",
  components: {
    WeatherContentHeader,
    EvercamLoadingAnimation,
    WeatherChart,
  },
  props: {
    selectedProject: {
      type: Object,
      default: () => {},
    },
    weather: {
      type: [Object, Array, null],
      default: null,
    },
    unitSystem: {
      type: String,
      default: () => "",
    },
    selectedTimestamp: {
      type: [String, Date],
      default: "",
    },
    selectedCamera: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      weatherAttachment: false,
      hoveredWeather: {
        wind: null,
        precipitation: null,
        temperature: null,
      },
      selectedUnitSystem: UnitSystem.Metric,
    }
  },
  computed: {
    ...mapStores(useLayoutStore, useWeatherStore),
    isMobile() {
      return this.$device.isMobile
    },
    timezone() {
      return this.selectedCamera?.timezone || "Europe/Dublin"
    },
    timestamp() {
      return (
        this.weatherStore.targetTimestamp ||
        this.selectedTimestamp ||
        new Date()
      )
    },
    isCurrentDay() {
      return (
        this.$moment.tz(this.timestamp, this.timezone).format("YYYY-MM-DD") ===
        this.$moment().format("YYYY-MM-DD")
      )
    },
    hideHeaderContent() {
      return !this.weather?.length || this.isLoading
    },
  },
  watch: {
    "weatherStore.dialog": {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.weatherStore.fetchWeatherData(
            this.timezone,
            this.selectedProject.exid
          )
        } else if (this.weatherStore.targetTimestamp) {
          this.weatherStore.targetTimestamp = null
        }
      },
    },
    "weatherStore.targetTimestamp": {
      async handler(val) {
        if (val && this.weatherStore.dialog) {
          await this.weatherStore.fetchWeatherData(
            this.timezone,
            this.selectedProject.exid
          )
        }
      },
    },
    "layoutStores.isFullscreen": {
      handler(fullscreen) {
        this.attachDialogToContainer(fullscreen)
      },
      immediate: true,
    },
    "weatherStore.selectedWeatherProvider": {
      immediate: true,
      async handler(val) {
        if (val && this.weatherStore.dialog) {
          await this.weatherStore.fetchWeatherData(
            this.timezone,
            this.selectedProject.exid
          )
        }
      },
    },
    unitSystem: {
      handler(value) {
        this.selectedUnitSystem = value
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on("image-player-fullscreen", (fullscreen) => {
      this.attachDialogToContainer(fullscreen)
    })
  },
  methods: {
    navigateHourlyWeather(hours) {
      const currentTimestamp = this.$moment.tz(this.timestamp, this.timezone)
      this.weatherStore.targetTimestamp = currentTimestamp
        .add({ hours })
        .format("YYYY-MM-DDTHH:mm:ssZ")
      this.$root.$emit("analytics-event", {
        eventId:
          hours > 0
            ? AnalyticsEvent.weatherGoForwardDate
            : AnalyticsEvent.weatherGoBackDate,
        params: {
          date: this.weatherStore.targetTimestamp,
        },
      })
    },
    navigateWeatherByDate(date) {
      const currentTimestamp = this.$moment.tz(date, this.timezone)
      this.weatherStore.targetTimestamp = currentTimestamp.format(
        "YYYY-MM-DDTHH:mm:ssZ"
      )
      this.$root.$emit("analytics-event", {
        eventId: AnalyticsEvent.weatherSelectDate,
        params: {
          date: this.weatherStore.targetTimestamp,
        },
      })
    },
    handleHoveredWeather(hoveredWeather) {
      this.hoveredWeather = hoveredWeather
    },
    attachDialogToContainer(fullscreen) {
      if (fullscreen) {
        this.weatherAttachment = this.$route.path.includes("recordings")
          ? ".player-container"
          : ".image-container"
      } else {
        this.weatherAttachment = false
      }
    },
  },
}
