
import OpenSupport from "@/mixins/openSupport"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import IosMediaDialog from "@/components/liveView/IosMediaDialog"

export default {
  components: {
    EvercamLoadingAnimation,
    IosMediaDialog,
  },
  mixins: [OpenSupport],
  data() {
    return {
      menu: false,
      hideTooltip: false,
      isLoadingZohoSupport: false,
      iosMediaDialog: false,
    }
  },
  watch: {
    menu() {
      this.hideTooltip = this.menu
    },
  },
  methods: {
    openUserManualForIos(url) {
      if (!this.$device.isIos) {
        return
      }
      this.iosMediaDialog = true
      this.iosMediaUrl = url
      this.menu = false
    },
  },
}
