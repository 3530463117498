
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { notificationFormatterMixin } from "@/mixins/notificationFormatterMixin"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { NotificationType } from "@evercam/shared/types/notification"

export default {
  mixins: [notificationFormatterMixin],
  data() {
    return {
      menu: false,
      hideTooltip: false,
      tab: "Unread",
      tabs: ["Unread", "Archive"],
      notifications: [],
      notificationTypes: NotificationType,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    formattedNotifications() {
      return this.notifications.map((notification) =>
        this.formatNotification(notification)
      )
    },
    unreadNotifications() {
      return this.formattedNotifications.filter(
        (notification) => notification.read === false
      )
    },
    hasUnreadNotifications() {
      return this.unreadNotifications.length > 0
    },
  },
  watch: {
    menu() {
      this.hideTooltip = this.menu
    },
  },
  mounted() {
    this.joinNotificationsWebsocketChannel()
  },
  methods: {
    notificationBackgroundColor(notification) {
      if (notification.read) {
        return "background"
      } else {
        if (this.$vuetify.theme.dark) {
          return "surface"
        } else {
          return "background darken-1"
        }
      }
    },
    async getUserNotifications() {
      try {
        const response = await EvercamApi.notifications.get()
        this.notifications = response.items
      } catch (error) {
        console.log(error)
        this.$errorTracker?.save(error)
      }
    },
    async handleNotificationClick(notification) {
      await this.markNotificationAsRead(notification.id)
      this.$router.push(notification.link)
    },
    async markNotificationAsRead(id) {
      try {
        const updatedNotification = await EvercamApi.notifications.markAsRead(
          id
        )

        this.notifications = this.notifications.map((notification) => {
          if (notification.id === updatedNotification.id) {
            return updatedNotification
          }

          return notification
        })
      } catch (error) {
        console.log(error)
      }
    },
    markAllAsRead() {
      this.unreadNotifications.forEach((notification) => {
        this.markNotificationAsRead(notification.id)
      })
    },
    joinNotificationsWebsocketChannel() {
      const userChannel = this.$websocket.channel(
        this.$config.public.socketURL,
        `users:${this.accountStore.email}`
      )
      userChannel.on("new-notifications", () => {
        this.handleNewNotification()
      })
    },
    handleNewNotification() {
      this.getUserNotifications()
    },
  },
}
