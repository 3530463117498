
import IngestUploadMenu from "@/components/appBar/IngestUploadMenu"
import NotificationsMenu from "@/components/appBar/NotificationsMenu"
import UserActionsMenu from "@/components/appBar/UserActionsMenu"
import HelpMenu from "@/components/appBar/HelpMenu"
import TheBreadcrumbs from "@/components/appBar/TheBreadcrumbs"
import { mapStores } from "pinia"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { useLayoutStore } from "@/stores/layout"
import { useAccountStore } from "@/stores/account"

export default {
  name: "TheAppBar",
  components: {
    IngestUploadMenu,
    NotificationsMenu,
    UserActionsMenu,
    HelpMenu,
    TheBreadcrumbs,
  },
  computed: {
    ...mapStores(useIngestFileUploaderStore, useLayoutStore, useAccountStore),
    hasIngestUploaded() {
      return this.ingestFileUploaderStore.uploadStats.reduce(
        (acc, state) =>
          state.isUploading ||
          state.isProcessing ||
          state.uploadFinished ||
          acc,
        false
      )
    },
  },
}
